<template>
  <div class="blog-page">
    <GlossaryArticleHeader :article="article" />
    <div class="article-main">
      <ArticleContent :article="article" />
      <ArticleSidebar :article="article" />
    </div>
    <ArticleRelated :article="article"/>
  </div>
</template>

<script>
import GlossaryArticleHeader from '@/components/GlossaryArticleHeader.vue';
import ArticleSidebar from '@/components/ArticleSidebar.vue';
import ArticleContent from '@/components/ArticleContent.vue';
import ArticleRelated from '@/components/ArticleRelated.vue';

export default {
  metaInfo() {
    return {
      title: 'Onlysales | ' + this.article.metaTitle,
      htmlAttrs: {
        lang: 'fr-FR'
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: this.article.metaDescription},
      ],
      link: [
        {rel: 'canonical', href: 'https://blog.onlysales.fr/glossaire/' + this.article.slug}
      ]
    }
  },

  components: {
    GlossaryArticleHeader,
    ArticleContent,
    ArticleSidebar,
    ArticleRelated,
  },

  data() {
    const slug = this.$route.params.slug
    const meta = this.$root.glossary.find(m => m.slug === slug)
      // 404 if article not found
    if (!meta) {
      this.$router.push('/glossaire')
    }

    const content = require(`@/contrib/glossary/${slug}.md`).default

    return {
      slug,
      article: {
        ...(meta || {}),
        content: content || '',
      },
    }
  },
  mounted() {
    const markdown = require(`@/contrib/glossary/${this.article.slug}.md`)
    this.article.content = markdown.default
  },
};
</script>

<style lang="scss">
.blog-page {
  background-color: $neutral25;

  .article-main {
    width: $desktopContainer;
    margin: 0 auto;
    
    .article-sidebar {
      float: left;
    }

    .article-content {
      float: right;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

@media screen and (max-width: $desktopContainer) {
  .blog-page {
    .article-main {
      width: $mobileContainer;

      .article-sidebar, .article-content {
        float: none;
      }
    }
  }
}
</style>